@use '@cck/common/dist/styles';
@use 'old_styles';

$main-view-header-height: 50px;

$main-view-left-frame-width: 278px;

$main-view-left-frame-collapse-width: 70px;

$main-view-dropdown-horiz-margin: 100px;

.main-view-wrapper-frame {
  width: 100vw;
  height: 100vh;
  position: relative;

  .main-view-dim-frame {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
  }
}

.main-view-frame {
  display: flex;
  width: 100%;
  height: 100%;

  .main-view-left-frame {
    display: flex;
    flex-direction: column;
    width: $main-view-left-frame-width;
    height: 100%;
    background-color: styles.$gray-100;

    .main-view-left-sider-inner-frame {
      display: flex;
      flex-direction: column;
      width: $main-view-left-frame-width;
      height: 100vh;
    }

    .main-view-left-header-icon-frame {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }

    .main-view-left-header-change-mode-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 2px;
      background-color: black;
      color: white;
      cursor: pointer;

      &-hover {
        background-color: styles.$marine-500;
      }

      &-collapsed {
        width: 40px;
        height: 40px;
      }
    }

    .main-view-left-sider-collapse-inner-frame {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: $main-view-left-frame-collapse-width;
      height: 100vh;
    }

    .main-view-left-header-frame {
      @extend %typo_subhead_03;
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      height: $main-view-header-height;
      padding: 10px 20px 5px 15px;
      border-bottom: 1px solid styles.$gray-300;

      .main-view-left-header-title {
        color: styles.$gray-600;
      }
    }

    .main-view-left-header-change-mode-frame {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: $main-view-header-height;
      padding: 0px 20px;
      cursor: pointer;
      background-color: white;

      .main-view-left-header-change-mode-text {
        @extend %typo_body_02;
        color: black;

        &-hover {
          color: styles.$marine-500;
        }
      }
    }

    .main-view-left-footer-frame {
      @extend %typo_caption_small;
      display: flex;
      align-items: center;
      width: 100%;
      height: $main-view-header-height;
      padding: 0px 20px;
      color: styles.$gray-500;
      border-top: 1px solid styles.$gray-300;
    }

    .main-view-left-body-frame {
      width: 100%;
      height: calc(100% - $main-view-header-height * 3);
    }
  }

  .main-view-right-frame {
    width: calc(100% - $main-view-left-frame-width);
    height: 100%;

    .main-view-right-content-inner-frame {
      width: 100%;
      height: 100%;
    }

    .main-view-right-scroll-frame {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      min-width: 1330px;
      height: 100%;
      // min-height: 1300px;
      background-color: white;

      .main-view-right-content-empty-frame {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 38px;
        width: 100%;
        height: 100%;

        .main-view-right-content-text-title {
          @extend %typo_display_01;
          color: black;
        }

        .main-view-right-content-item-frame {
          display: flex;
          justify-content: center;
          width: 100%;
          min-height: 200px;
          padding: 0px 80px;

          .main-view-right-content-item {
            @extend %typo_body_01;
            display: flex;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            border: 1px solid #C6D1FC;
          }
        }
      }
    }

    .main-view-right-header-frame {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      height: $main-view-header-height;
      padding: 0px 20px;
      border-bottom: 1px solid old_styles.$border-color;

      .main-view-right-header-item {
        @extend %typo_subhead_02;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        user-select: none;
      }

      .main-view-right-header-search-frame {
        @extend %typo_body_01;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 35px;
        padding: 2px 48px 2px 16px;
        border-radius: 100px;
        border: 1px solid styles.$gray-400;
        color: styles.$gray-400;
        cursor: pointer;
      }
    }

    .main-view-right-body-frame {
      width: 100%;
      height: calc(100% - $main-view-header-height);
    }
  }

  .main-view-dropdown-menu-frame {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid old_styles.$border-color;
    width: calc(100% - $main-view-dropdown-horiz-margin * 2);
    max-height: 1000px;
    opacity: 1;
    position: absolute;
    z-index: 100;
    top: calc($main-view-header-height + 4px);
    left: $main-view-dropdown-horiz-margin;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
    padding: 20px;
  }

  .main-view-dropdown-header-frame {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0px 20px;
    width: 100%;
    height: $main-view-header-height;
    background-color: white;
    z-index: 100;

    .main-view-dropdown-header-search-frame {
      height: 35px;
    }
  }
}

.left-sider-collapse-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  width: 18px;
  height: 44px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid styles.$gray-300;

  &:hover {
    background-color: styles.$gray-100;
  }
}
