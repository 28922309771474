@use 'old_styles'

.TotalSearch
  position: relative
  box-sizing: border-box
  display: flex
  flex-direction: row
  align-items: flex-start
  padding: 0px
  margin: 0 auto
  width: 200px
  height: 36px
  border: 1px solid #DEE2E6
  border-radius: 2px

.TotalSearchInput
  display: flex
  flex-direction: row
  align-items: center
  padding: 12px
  width: 156px
  height: 34px
  border: none
  flex: none
  order: 0
  flex-grow: 1

.TotalSearchInput::placeholder
  color: #ADB5BD
  font-weight: 500
  font-size: 14px
  opacity: 0.5

.TotalSearchInput:focus
  outline: none !important
  border: 1px solid #228BE6
  caret-color: #228BE6

.TotalSearchInputAlert
  outline: none !important
  border: 1px solid #F03E3E

.TotalSearchIcon
  cursor: pointer
  box-sizing: border-box
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: 4px
  gap: 10px
  width: 44px
  height: 36px
  border-left: 1px solid #DEE2E6
  flex: none
  order: 1
  flex-grow: 0

.TotalSearchIconSvg
  height: 60%
  fill-opacity: 0.6

.TotalSearchDropdownArea
  z-index: 10
  width: 100%
  height: calc(100vh - 80px)
  display: block

.TotalSearchDropdownMenuArea
  width: 100%
  height: 50%
  display: flex

.TotalSearchDropdownMenuSideArea
  width: 25%
  height: 100%
  background-color: gray
  opacity: 0.4

.TotalSearchDropdownMenu
  width: 50%
  height: 100%
  background-color: white

.TotalSearchDropdownMenuBottomArea
  width: 100%
  height: 50%
  background-color: gray
  opacity: 0.4
  text-align: center
  font-size: 20px
  color: white

.RecentTotalSearchResultArea
  width: 100%
  border-bottom: old_styles.$border

.RecentTotalSearchHistory
  width: 100%
  height: 20px
  margin: 20px

.RecentTotalSearchListArea
  width: 100%
  height: 30px
  margin: 20px
  display: flex

.RecentTotalSearchList
  border: old_styles.$border
  width: 50px
  border-radius: 50px
  font-weight: bold
  text-align: center
  margin-right: 10px

.AutoCompleteKeywordsFrame
  display: flex
  flex-direction: column
  align-items: flex-start
  position: absolute
  top: 38px
  width: 240px
  background: #FFFFFF
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2)
  border-radius: 4px
  flex: none
  z-index: 3
  padding-bottom: 16px

.AutoCompleteKeywordsPlaceHolderText
  font-family: 'Pretendard'
  font-style: normal
  font-weight: 700
  font-size: 12px
  line-height: 14px
  color: #ADB5BD
  margin: 10px 0px
  padding: 10px 16px

.AutoCompleteKeywordText
  font-family: 'Pretendard'
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 17px
  width: 100%
  padding: 10px 16px

.AutoCompleteKeywordText:hover
  background: #F1F3F5
  cursor: pointer

.AutoCompleteKeywordMatch
  color: #228BE6
