@use "../../styles";

.ant-tag {
  @extend %typo_tag;
  border-radius: 3px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 10px;
  color: styles.$gray-800;
  margin-inline-end: 0px;
}

.cck-tag {
  height: 20px;
}

.ant-space-item {
  display: flex;
}
.cck-tag-blue-border {
  border: 1px solid styles.$blue-500;
}

.cck-tag-green-border {
  border: 1px solid styles.$green-500;
}

.cck-tag-green-border {
  border: 1px solid styles.$green-500;
}

.cck-tag-yellow-fill {
  background-color: styles.$yellow-50;
}

.cck-tag-blue-fill {
  background-color: styles.$blue-500;
  color: white;
}

.cck-tag-green-fill {
  border: 0 !important;
  background-color: styles.$green-700;
  color: white;
}

.cck-tag-container {
  display: flex;
}

.cck-tag-container-center {
  justify-content: center;
  width: 100%;
}

.cck-tag-overflow-text {
  width: 0px;
  height: 0px;
  position: relative;
  top: 5px;
}

.cck-tag-wrapper {
  display: flex;
  // justify-content: center;
}

.ant-space-item-disabled {
  display: none;
}

.tag-container-full-wrapper {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
}