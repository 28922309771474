@use "@cck/common/dist/styles"

.chat-main-view-sider
  text-align: center

.chat-main-view-sider-frame
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  overflow: hidden

.chat-main-view-sider-main-wrapper
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  div
    text-wrap: nowrap
.chat-main-view-sider-header
  display: flex
  justify-content: space-between
  border-bottom: 1px solid styles.$gray-300
  background-color: styles.$gray-100
  height: 50px
  .chat-main-view-sider-title
    @extend %typo_subhead_03
    text-align: center
    display: flex
    gap: 10px
    color: black
    align-items: center
    padding: 10px 20px 5px 15px
    justify-content: center

    .chat-main-view-sider-title-chat
      color: styles.$gray-600

  .chat-main-view-sider-title-collapsed
    padding: 5px 0 5px 14px

    .chat-main-view-sider-title-button
      width: 40px
      height: 40px
      display: flex
      align-items: center
      justify-content: center
      background-color: styles.$blue-500
      border-radius: 3px

.chat-main-view-sider-content-wrapper
  width: 100%
  flex-grow: 1
  display: flex
  flex-direction: column
  align-items: center
  padding: 16px
  gap: 27px
  background-color: styles.$gray-100
.chat-main-view-sider-new-chat-btn
  @extend %typo_body_02
  width: 100%
  height: 40px
  display: flex
  justify-content: space-between
  align-items: center
  color: #fff
  background-color: styles.$marine-500
  border-radius: 3px
  padding: 10px
  cursor: pointer
.chat-main-view-sider-subtitle
  @extend %typo_subhead_02
  display: flex
  justify-content: space-between
  align-items: center
  color: #000
  padding-right: 12px

.chat-main-view-scrap-menu
  background: styles.$gray-100
  .ant-menu-submenu-arrow
    color: black
  .ant-menu-submenu-title
    padding: 0px !important
    padding-inline: 0px !important
  .ant-menu-sub
    background-color: transparent !important
  .ant-menu-item-selected
    background-color: styles.$gray-200 !important
  .ant-menu-item
    padding-left: 26px !important
  .ant-menu-title-content
    width: 100%
    .chat-view-scrap-sub-label
      width: 100%
      overflow: hidden
      text-overflow: ellipsis
      text-wrap: nowrap
.chat-main-view-sider-user-info-wrapper
  width: 100%
  height: 40px
  display: flex
  justify-content: center
  align-items: center
  color: #fff
  background-color: styles.$marine-500

.chat-content-wrapper-view-sider
  width: 100%
  height: 100%
  border-left: 1px solid styles.$gray-300
  padding: 16px 0px 

  .chat-content-sider-reference-list
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    padding: 16px
    gap: 20px

    .chat-content-sider-reference-option-wrapper
      width: 100%
      display: flex
      justify-content: flex-end

    .chat-content-sider-reference-wrapper
      width: 100%
      display: flex
      flex-direction: column
      gap: 16px

      .chat-content-sider-reference-title-wrapper
        display: flex
        flex-direction: row
        align-items: center
        gap: 10px

        .chat-content-sider-reference-index-wrapper
          @extend %typo_caption
          width: 18px
          height: 18px
          display: flex
          align-items: center
          justify-content: center
          border: 1px solid styles.$gray-600
          border-radius: 3px
          padding: 4px

        .chat-content-sider-reference-title
          @extend %typo_subhead_02

      .chat-content-sider-reference-description-wrapper
        @extend %typo_body_01
        padding: 0px 10px

      .chat-content-sider-reference-description-short
        overflow: hidden
        text-overflow: ellipsis
        display: -webkit-box
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical

      .chat-content-sider-reference-description-option-wrapper
        @extend %typo_subhead_01
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        padding: 5px 10px
        border: 1px solid styles.$gray-200
        border-radius: 3px
        background: styles.$gray-100

        &:hover
          background: styles.$gray-200



      .chat-content-sider-reference-option-wrapper
        width: 100%
        display: flex
        gap: 16px
        justify-content: flex-start

.chat-main-view-editor-list
  display: flex
  flex-direction: column
  gap: 14px
  align-items: center
  padding-top: 10px
  .chat-main-view-button
    width: 40px
    height: 40px
    display: flex
    align-items: center
    justify-content: center
  .chat-main-view-switch-button
    background-color: black
    color: white
    &:hover
      background-color: styles.$marine-500
  .chat-main-view-add-button
    background-color: styles.$marine-500
  .chat-main-view-add-button:hover
    &:hover
      background-color: styles.$marine-600
.chat-main-view-sider-collapsed-frame
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  background-color: styles.$gray-100
.chat-main-view-sider-switch-btn
  @extend %typo_body_02
  width: 100%
  height: 50px
  padding: 10px 20px
  display: flex
  justify-content: space-between
  align-items: center
  cursor: pointer
  .chat-main-view-sider-switch-btn-icon
    width: 24px
    height: 24px
    display: flex
    align-items: center
    justify-content: center
    background-color: black
    color: white
    border-radius: 3px
.chat-main-view-sider-switch-btn:hover
  color: styles.$marine-500
  .chat-main-view-sider-switch-btn-icon
    background-color: styles.$marine-500

.chat-view-scrap
  color: white !important
  stroke: black

.chat-view-scrap-label
  @extend %typo_body_02
  color: black
  text-align: left
  padding-left: 10px
.chat-view-scrap-sub-label
  @extend %typo_body_01
  color: styles.$gray-800
  text-align: left
.chat-main-view-sider-conversation
  width: 100%
  display: flex
  flex-direction: column
  gap: 10px
  .chat-main-view-sider-conversation-list
    width: 100%
    display: flex
    flex-direction: column
    gap: 20px
    align-items: flex-start
    .chat-main-view-sider-conversation-sub-list
      width: 100%
      display: flex
      flex-direction: column
      gap: 10px
      .chat-main-view-sider-conversation-sub-list-title
        @extend %typo_body_01
        color: styles.$gray-600
        text-overflow: ellipsis
        text-align: left
      .chat-main-view-sider-conversation-sub-list-item-list
        width: 100%
        display: flex
        flex-direction: column
        gap: 5px
        .chat-main-view-sider-conversation-item
          @extend %typo_body_02
          padding: 10px 10px
          display: block
          border-radius: 3px
          cursor: pointer
          width: 100%
          overflow: hidden
          text-overflow: ellipsis
          text-align: left
          &:hover
            background-color: styles.$gray-200

.chat-main-view-sider
  .left-sider-collapse-button
    left: auto !important
    right: -22px !important