@use "../../styles";

$search-input-control-frame-height: 48px;
$search-input-control-padding-left: 12px;
$search-input-control-padding-right: 16px;
$search-input-control-child-gap: 8px;
$search-input-control-icon-size: 24px;

.search-input-control-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: $search-input-control-frame-height;
  border-radius: 2px;
}

.search-input-control-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 $search-input-control-padding-right 0 $search-input-control-padding-left;
  gap: $search-input-control-child-gap;
  // parent display flex에 영향받지 않도록 세팅
  width: calc(100% - 2px);
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  border: none;
  outline: 1px solid styles.$gray-200;
  border-radius: 2px;
}

.search-input-control-form-outline-none {
  outline: none;
}

.search-input-control-form-focus {
  outline: 1.5px solid styles.$blue-700;
}

.search-control-icon-frame {
  width: $search-input-control-icon-size;
  height: $search-input-control-icon-size;
}

.search-control-icon {
  width: 100%;
  height: 100%;
  color: styles.$gray-400;
}

.search-input-icon-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $search-input-control-icon-size;
  height: $search-input-control-icon-size;
}

.search-keyword-box-frame {
  display: flex;
  width: 100%;
}

.search-keyword-box {
  width: 100%;
  border-radius: 5px;
  background-color: styles.$gray-600;

  .cck-tag-container {
    margin: 10px 12px;
  }
}
