@use 'components';
@use 'old_styles';

.AppRoot {
  width: 100vw;
  height: 100vh;
  display: flex;
  user-select: none;
  max-width: old_styles.$app-root-max-width;
  border-left: 1px solid #F1F3F5;
  border-right: 1px solid #F1F3F5;
  margin: 0 max(calc((100% - 1920px)/2), 0px);
  position: absolute;
}

.linear_progress {
  width: 100%;

  span {
    background: #1a90ff;
  }
}

body {
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-feature-settings: "ss05";
}