@use "../../styles";

.empty-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%;
    height: 100%;
    min-height: 250px;

    .empty-content-image {
        height: 100px;
    }
}

.empty-content-wrapper-default {
    background-color: styles.$gray-600;
}

.empty-content-text-default {
    @extend %typo_body_02;

    color: styles.$gray-300;
}
