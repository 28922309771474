@use "../../styles";

.drop-down-menu-frame {
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 164px;
  padding: 6px 0px;
  background: white;
  border: 1px solid styles.$gray-200;
  box-shadow: 0 4px 16px rgb(0 0 0 / 16%);
  border-radius: 2px;
  overflow-y: auto;
}

.drop-down-menu-item {
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  align-self: stretch;

  background: white;
  color: styles.$gray-700;

  cursor: pointer;

  &:hover {
    background: styles.$blue-100;
    color: styles.$blue-500;
  }
}

.drop-down-menu-item-custom {
  @extend .drop-down-menu-item;
  padding: 0px;
}
