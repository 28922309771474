@use '@cck/common/dist/styles';

.search-standard-control-frame {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.search-standard-control-header-frame {
  width: 300px;
}

.main-view-dropdown-header-search-history-frame {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;

  .main-view-dropdown-header-search-history-item {
    @extend %typo_caption;
    display: flex;
    padding: 1px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    border: 1px solid styles.$gray-100;
  }

  .main-view-dropdown-header-search-history-item-span {
    @extend %typo_caption;
    display: inline-flex;
    padding: 1px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    border: 1px solid styles.$gray-100;
  }
}

.search-count-item {
  @extend %typo_caption_small;
  display: inline-flex;
  padding: 0px 6px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid styles.$gray-100;
  line-height: 13px;
}

.search-count-item-standard {
  border-radius: 10px;
  border: 1px solid styles.$gray-100;
  width: 14px;
  height: 12px;
}

.search-count-list-overlap-frame {
  display: inline-flex;
  position: relative;
  margin-left: 4px;
  vertical-align: middle;
}