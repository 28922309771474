@use "../../styles";

.cck-base-icon {
  width: 18px;
  height: 18px;
}

.icon-enable {
  color: styles.$gray-700;
}

.icon-disable {
  color: styles.$gray-400;
}

.icon-error {
  color: styles.$red-600;
}

.icon-enable-white {
  color: white;
}

.icon-check {
  color: styles.$blue-700;
}

.icon-enable-red {
  color: styles.$red-500;
}

.icon-enable-amber {
  color: styles.$amber-500;
}

.icon-enable-blue {
  color: styles.$blue-500;
}

.cck-base-svg-icon-wrapper {
  display: flex;
}

.cck-base-icon-clickable {
  cursor: pointer;
}