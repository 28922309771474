@use "@cck/common/dist/styles"

$bookmark_text: #228BE6

.DescriptionItem
  display: flex
  flex-direction: row
  user-select: text
  margin-bottom: 10px
  padding: 4px
  p
    display: block
    margin: 0 0 4px 0
    word-break: keep-all
    line-height: 160%
    font-size: 15px
    font-weight: 400
  h2
    font-size: 20px
    font-weight: 700
  h3, h4
    font-size: 16px
    font-weight: 700
  h5
    font-size: 15px
    font-weight: 700
  h6
    font-size: 15px
    font-weight: 500

.DescriptionItemLinkHighlight
  background-color: styles.$marine-100

.DescriptionName
  color: #ADB5BD
  width: 100px
  height: fit-content
  align-items: center
  flex-shrink: 0
  display: flex
  flex-direction: row
  margin-right: 10px
  p
    word-break: keep-all
    word-wrap: break-word
    min-width: 0
    font-family: 'Pretendard'
    font-weight: 700
    font-size: 15px
    line-height: 140%
    margin: 0

.DescriptionNameWithBookmark
  color: styles.$marine-500

.DescriptionText
  flex-grow: 1
  width: 100px
  font-family: 'Pretendard'
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 160%
  color: #343A40

  > .excel_div, .excel_table_type, .excel_example_type, .excel_attention_type
    &:first-child
      padding-top: 0

.Bookmark
  cursor: pointer
  text-align: center
  align-items: center
  justify-content: center
  display: flex
  height: 18px
  width: 15px
  margin-top: 2px
  margin-right: 7px

.BookmarkIconSvg
  cursor: pointer

.UnBookmarkedIcon
  cursor: pointer
