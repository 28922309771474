@use "../../styles";

$card-menu-container-frame-padding-top: 40px;

.card-menu-container-frame {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 18px;
}

.card-menu-frame-wrapper:hover {
  .card-menu-frame {
    background-position: left bottom;
    scale: 0.95;
  }
}

.card-menu-frame {
  padding: 24px 20px;
  border-radius: 5px;
  box-shadow:
    0px 3px 8px 0px rgba(0, 0, 0, 0.12),
    0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  background: linear-gradient(to right, styles.$blue-50 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.2s ease-out;
  transform: translateZ(-500px) scale(0.7); /* 뒤에서 시작 */
  perspective: 500px; /* 3D 효과를 위한 원근감 */

  &-normal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 23px;
    width: 284px;
    height: 140px;
  }

  &-long {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 23px;
    width: 590px;
    height: 82px;
  }
}

.card-memu-frame-transition {
  transform: translateZ(0) scale(1); /* 최종 위치와 크기 */
}

.card-menu-item-frame {
  width: 40px;
  height: 40px;
}

.card-menu-title-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
}

.card-menu-title-text {
  @extend %typo_heading_h6;

  color: styles.$blue-500;
  // padding-bottom: 2px;
  background-image: linear-gradient(white 0 0);
  background-size: 0 2px;
}

.card-menu-title-text-dark {
  color: styles.$gray-800;
}

.card-menu-title-text-hover {
  background-image: linear-gradient(to right, styles.$blue-500 50%, rgba(255, 255, 255, 0));
  background-position: 0% 100%; // left bottom
  background-size: 200% 2px;
  background-repeat: no-repeat;
  transition: background-size 0.3s; /*change after the size immediately*/
}

.card-menu-title-guide-text {
  @extend %typo_body_01;

  color: styles.$gray-600;
}

.card-menu-icon-frame {
  display: flex;
  height: 40px;
  align-items: center;
}

.card-menu-header-frame {
  display: flex;
  gap: 9px;
  align-items: center;
}

.card-menu-header-text {
  @extend %typo_subhead_03;
  color: white;
}

.card-menu-additional-text {
  @extend %typo_subhead_02;
  position: absolute;
  right: 10px;
  background: black;
  color: white;
  width: 77px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px 0px 0px 2px;
}
