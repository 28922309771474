$gray-50: #f8f9fa;
$gray-100: #f1f3f5;
$gray-200: #e5e8eb;
$gray-300: #d1d5d9;
$gray-400: #b5babf;
$gray-500: #989fa6;
$gray-600: #727980;
$gray-700: #40464d;
$gray-750: #383e46;
$gray-800: #252a2e;
$gray-900: #101214;

$blue-50: #e7f5ff;
$blue-100: #d0ebff;
$blue-200: #a5d8ff;
$blue-300: #74c0fc;
$blue-400: #42a5f5;
$blue-500: #2095f3;
$blue-600: #1e87e5;
$blue-700: #1976d2;
$blue-800: #145eb2;
$blue-900: #0c4399;

$red-50: #fff5f5;
$red-100: #ffe3e3;
$red-200: #ffc9c9;
$red-300: #ff8787;
$red-400: #ff6b6b;
$red-500: #fa5252;
$red-600: #f03c37;
$red-700: #de3131;
$red-800: #c62828;
$red-900: #b71c1c;

$green-50: #F6FFFB;
$green-100: #E4FDF2;
$green-200: #CFF2D0;
$green-300: #9AEB9D;
$green-400: #5CDB61;
$green-500: #00ce46;
$green-600: #00ae3b;
$green-700: #009c35;
$green-800: #00852d;
$green-900: #005c1f;

$yellow-50: #fff9db;
$yellow-100: #fff3bf;
$yellow-600: #fab005;
$yellow-800: #f08c00;

$lime-50: #f7fae6;
$lime-100: #ebf2c1;
$lime-200: #deeb99;
$lime-300: #d1e370;
$lime-400: #c7dd51;
$lime-500: #bed731;
$lime-600: #b2c52b;
$lime-700: #a2af22;
$lime-800: #92981a;
$lime-900: #596926;

$amber-50: #fff9db;
$amber-100: #fff3bf;
$amber-200: #ffe082;
$amber-300: #ffd54f;
$amber-400: #ffca28;
$amber-500: #ffc107;
$amber-600: #ffb300;
$amber-700: #ffa000;
$amber-800: #ff8f00;
$amber-900: #ff6f00;

$marine-100: #ECF0FE;
$marine-200: #C6D1FC;
$marine-300: #869EF8;
$marine-500: #4267F4;
$marine-600: #2B4ED3;

$ivory-100: #F0EAD6;