%typo_heading_h1 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 76.8px;
}

%typo_heading_h2 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 57.6px;
}

%typo_heading_h3 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 51.2px;
}

%typo_heading_h4 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 38.4px;
}

%typo_heading_h5 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

%typo_heading_h6 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 28.8px;
}

%typo_xlarge {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
}

%typo_xlarge_bold {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

%typo_large {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

%typo_large_bold {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
}

%typo_large_medium {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
}

%typo_medium {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
}

%typo_medium_medium {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22.4px;
}

%typo_xxsmall {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 17.6px;
}

%typo_xsmall {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 19.2px;
}

%typo_xsmall_medium {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 19.2px;
}

%typo_xsmall_bold {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 19.2px;
}

%typo_small {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20.8px;
}

%typo_small_medium {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
}

%typo_small_bold {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 20.8px;
}

%typo_medium_bold {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 22.4px;
}

%typo_button {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

%typo_small_button {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

%typo_display_05 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: -0.6px;
}

%typo_display_04 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: -0.6px;
}

%typo_display_03 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.6px;
}

%typo_display_02 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.2px;
}

%typo_display_01 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.2px;
}

%typo_headline {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.2px;
}

%typo_subhead_04 {
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  /* 155.556% */
  letter-spacing: -0.2px;
}

%typo_subhead_03 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.2px;
}

%typo_subhead_long_03 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.2px;
}

%typo_subhead_02 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.2px;
}

%typo_subhead_long_02 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
}

%typo_subhead_01 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.2px;
}

%typo_body_02 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
}

%typo_body_long_02 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.2px;
}

%typo_body_01 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.2px;
}

%typo_body_long_01 {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
}

%typo_caption {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
}

%typo_caption_small {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
}

%typo_tag {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
}

%typo_memo_count {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
}

%typo_comment_button {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
}