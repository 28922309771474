@use '@cck/common/dist/styles';

.SelectedContentItem {
  font-style: normal;
  font-weight: 700;
  color: styles.$marine-500;
}

@mixin common-item {
  width: 100%;
  word-break: keep-all;
  cursor: pointer;
  font-family: 'Pretendard';
  font-style: normal;

  &:hover {
    background-color: #F1F3F5;
  }
}

.ContentItemLevel {
  padding: 0;

  &-1 {
    @include common-item;
    font-weight: bold;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  &-2 {
    @include common-item;
    display: inline-block;
    font-weight: 700;
    font-size: 15px;
    line-height: 140%;
    font-family: 'Pretendard';
    margin-top: 20px;
  }

  @for $i from 3 through 10 {
    &-#{$i} {
      @include common-item;
      margin-top: 6px;
      padding-left: 10px;
      font-size: 14px;
      line-height: 140%;
    }
  }
}

.ContentLevelBullet {
  padding-top: 2px;
  display: inline-block;
  vertical-align: text-bottom;
  width: 4px;
  line-height: 135%;

  svg {
    margin-bottom: 0.1rem;
  }
}

.ContentName {
  // margin-left: 8px;
}

.ContentSubname {
  font-size: 12px;
  color: #9a9a9a;
}

.SearchResultExist {
  // background-color: #F8F9FA;
}

.ContentItemChildBox {
  margin-left: 10px;
  border-left: 1px solid #B5BABF;
}