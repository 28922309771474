@use "../../styles";

.page-label-frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  height: 34px;
}

.page-label-icon-frame {
  width: 26px;
  height: 26px;
}

.page-label-text-frame {
  @extend %typo_headline;

  color: white;
}