@use "../../../styles";

.confirm-modal-frame{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 0px;
}

.confirm-modal-frame-top-border {
  border-top: 1px solid styles.$gray-200;
}

.confirm-modal-input-label {
  @extend %typo_subhead_02;

  padding-bottom: 10px;
  width: 320px;
}

.confirm-modal-word {
  color: styles.$blue-500;
}

.confirm-modal-word-postfix {
  color: styles.$gray-900;
}

.modal-footer-frame-top-border {
  border-top: 1px solid styles.$gray-200;
}

.confirm-button-list-wrapper {
  margin-top: 11px;
}

.confirm-modal-error-message {
  @extend %typo_body_long_01;

  color: styles.$red-500;
  padding-top: 4px;
  width: 320px;
}

.confirm-control-icon {
  color: styles.$green-600;
}

.confirm-modal-input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  &-center {
    align-items: center;
  }
  &-left {
    align-items: start;
  }
}
