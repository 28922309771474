@use "../../styles";

$notification-holder-width: 470px;

.notifications-control-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    right: 16px;
    top: 16px;
    width: $notification-holder-width;
    height: 30px;
    z-index: 9999;
    padding: 0px 12px;
    pointer-events: none;

    .notifications-control-right-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;

        .notifications-control-base {
            @extend %typo_caption;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            background-color: styles.$gray-700;
            border: 1px solid styles.$gray-600;
            color: styles.$gray-100;
            padding: 0px 8px;
            height: 20px;
            cursor: pointer;
            position: relative;
            pointer-events: all;
        }

        .notifications-control-right-simply {
            @extend .notifications-control-base;
            transition: all 0.4s ease-in-out;

            &-long {
                width: 100px;

                .notifications-control-label {
                    min-width: 70px;
                }
            }

            &-short {
                width: 20px;
            }
        }

        .notifications-control-right-close-all {
            @extend .notifications-control-base;
            transition: all 0.4s ease-in-out;

            &-long {
                width: 100px;

                .notifications-control-label {
                    min-width: 70px;
                }
            }

            &-short {
                width: 20px;
            }
        }

        .notifications-control-label {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            transition: opacity 0.4s ease;
            opacity: 1;
        }

        .notifications-control-label-hidden {
            opacity: 0;
        }
    }
}

.notification-basic-frame {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: $notification-holder-width;
    min-height: 72px;
    max-height: 144px;
    border-radius: 5px;
    background-color: white;
    box-shadow:
        0px 3px 8px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    .notification-basic-left-color-bar-base {
        width: 8px;
        height: 100%;
        border-radius: 5px 0px 0px 5px;
        margin-right: 5px;
    }

    .notification-basic-left-color-bar {
        &-error {
            @extend .notification-basic-left-color-bar-base;
            background-color: styles.$red-500;
        }

        &-success {
            @extend .notification-basic-left-color-bar-base;
            background-color: styles.$green-500;
        }

        &-info {
            @extend .notification-basic-left-color-bar-base;
            background-color: styles.$blue-500;
        }

        &-warning {
            @extend .notification-basic-left-color-bar-base;
            background-color: styles.$amber-500;
        }
    }

    .notification-basic-icon-wrapper-base {
        display: flex;
        align-items: center;
        width: 24px;
        height: 100%;
    }

    .notification-basic-icon-wrapper {
        &-error {
            @extend .notification-basic-icon-wrapper-base;
            color: styles.$red-500;
        }

        &-success {
            @extend .notification-basic-icon-wrapper-base;
            color: styles.$green-500;
        }

        &-info {
            @extend .notification-basic-icon-wrapper-base;
            color: styles.$blue-500;
        }

        &-warning {
            @extend .notification-basic-icon-wrapper-base;
            color: styles.$amber-500;
        }
    }

    .notification-basic-content-wrapper {
        display: flex;
        flex-direction: column;
        padding: 12px;
        gap: 4px;
        width: 100%;

        .notification-basic-content-title {
            @extend %typo_subhead_03;
            color: styles.$gray-900;
        }

        .notification-basic-content-description {
            @extend %typo_body_01;
            color: styles.$gray-600;
            white-space: pre-wrap;
        }
    }

    .notification-basic-close-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        margin-right: 12px;
        cursor: pointer;

        &:hover {
            background-color: styles.$gray-200;
        }
    }

    .notification-basic-backframe {
        @extend .notification-basic-frame;
        position: absolute;
    }

    .notification-basic-close-all {
        display: none;
    }

    &:hover {
        background-color: styles.$gray-100;

        .notification-basic-close-all-base {
            @extend %typo_caption;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            background-color: styles.$gray-700;
            border: 1px solid styles.$gray-600;
            color: styles.$gray-100;
            padding: 0px 4px;
            height: 20px;
            cursor: pointer;
            position: absolute;
            right: -10px;
            top: -10px;
        }
    }
}
