@use "../../styles";

.cck-form-item {
  margin: 4px 0;
}

.cck-input {
  @extend %typo_body_02;

  border-radius: 2px;
  height: 100%;
  padding: 12px 16px;
  border: 1px solid styles.$gray-400;
}

.cck-input::placeholder {
  color: styles.$gray-400 !important;
}

.cck-input:disabled {
  color: styles.$gray-900;
  border: 1px solid styles.$gray-400;
}

.new-search-control-icon {
  color: styles.$gray-400;
}

.new-auto-input {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  .new-auto-input-loading-progress {
    width: 0px;
    left: -30px;
    position: relative;
  }
}

.new-auto-input-text-frame {
  width: 100%;
  height: 100%;

  .ant-select-selector {
    width: 100%;
    height: 100%;
  }

  // .cck-input {
  //   // border: 1px solid styles.$gray-200;
  // }
}

.cck-auto-complete {
  padding: 12px 0;

  .ant-select-item {
    padding: 8px 12px;
  }

  .ant-select-item-option-active {
    background: styles.$blue-50 !important;
    color: styles.$blue-500 !important;
  }
}

.cck-password-input .cck-email-input {
  // @extend %typo_body_02;
  width: 100%;
  font-size: 16px !important;
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
}

.cck-search-input {
  // @extend %typo_body_01;
  width: 350px;
  font-size: 14px !important;
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  height: 35px;
  padding: 0 16px;
}

.cck-basic-input {
  // @extend %typo_body_02;
  width: 320px;
  font-size: 16px !important;
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cck-table-auto-complete-input {
  width: 100%;
}
