@use "../../styles";

.error-log-list-wrapper {
  padding-top: 20px;

  .error-log-list-inner {
    display: flex;
    flex-direction: column;
    width: 1155px;
    padding: 10px;
    background-color: styles.$red-50;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 5px;
    border: 1px solid styles.$red-200;

    .error-log-list-header {
      @extend %typo_large_bold;

      color: styles.$red-600;
    }

    .error-log-list-error {
      @extend %typo_small_medium;

      color: styles.$gray-900;
    }
  }
}
