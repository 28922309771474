@use "../../styles";

.cck-files-uploader {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .cck-files-uploader-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cck-files-uploader-files-pc {
    @extend %typo_body_long_01;
    padding: 1px 10px;
    border-radius: 2px;
    border: 1px solid styles.$gray-400;
    color: styles.$gray-900;
    cursor: pointer;
  }


  .cck-files-uploader-files-warning {
    @extend %typo_body_long_01;
    color: styles.$gray-600;
  }

  .cck-files-uploader-files-list {
    width: 100%;
    border: 1px solid styles.$gray-200;
  }

  .cck-files-uploader-files-empty {
    @extend %typo_body_02;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: styles.$gray-500;
  }

  .cck-files-uploader-files-table {
    min-height: 124px;
  }

  .cck-files-uploader-files-table-header {
    @extend %typo_body_01;
    display: flex;
    padding: 4px 32px 4px 11px;
    background-color: styles.$gray-200;
    align-items: center;
    color: styles.$gray-600;
  }

  .cck-files-uploader-files-table-row {
    @extend %typo_body_02;
    display: flex;
    padding: 4px 32px 4px 11px;
    align-items: center;
  }

  .cck-files-uploader-files-table-title {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .cck-files-uploader-files-table-volume {
    width: 300px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cck-files-uploader-files-table-task {
    display: flex;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    padding-left: 0;
  }
}

.cck-files-download-files-table-volume {
  width: 100px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cck-files-download-files-table-download {
  width: 100px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cck-files-download-table-text {
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
}
