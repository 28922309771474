@use "../../../styles";

// CCK Table 공통 적용 Style
.ag-theme-alpine {
  // 디폴트 폰트로 적용함
  // important 뺼 수 있는 방법을 찾아봐야함
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif !important;

  .ag-cell {
    // 각 cell의 기본 padding
    display: flex;
    padding-left: 0px !important;
    padding-right: 0px !important;
    --ag-value-change-value-highlight-background-color: #42a5f5;
  }

  .ag-header-cell {
    // 각 cell의 기본 padding
    padding: 10px 16px;
  }
}

.cck-table-theme {
  // default font family
  --ag-font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;

  // Theme 가이드 ** rgb 값을 적용해야함 **
  // 1. 전반적인 color 설정
  // --ag-foreground-color: rgb값 입력; (전체적인 폰트 등 front color 설정)
  // --ag-background-color: rgb값 입력; (배경색 등 back color 설정)
  // 2. column header color 설정
  // --ag-header-background-color: rgb값 입력; (column header의 전체적인 폰트 등 front color 설정)
  // --ag-header-foreground-color: rgb값 입력; (column header의 배경색 등 back color 설정)
  // 3. 추가적인 설정
  // --ag-odd-row-background-color: rgb값 입력; (홀수 row 배경색 설정)
  // --ag-header-column-resize-handle-color: rgb값 입력; (resize handle color 설정)

  // CCK Table Default Theme(Skyblue)
  &-default {
    // column header background color - blue 100(#d0ebff)
    --ag-header-background-color: #d0ebff;
  }

  &-lightgray {
    // column header background color - gray 100(#f1f3f5)
    --ag-header-background-color: #f1f3f5;
  }

  &-gray {
    // column header background color - gray 300(#d1d5d9)
    --ag-header-background-color: #d1d5d9;
  }

  .ag-root-wrapper {
    border: 0px !important;
  }
  .ag-body-viewport {
    height: 0px !important;
  }
}

.cck-table-pinned-column-theme {
  &-default {
    .ag-theme-alpine {
      .ag-pinned-left-header {
        background: styles.$gray-100;
      }
      .ag-pinned-right-header {
        background: styles.$gray-100;
      }
    }
  }
}

.cck-cell-flash {
  &-error {
    .ag-cell {
      --ag-value-change-value-highlight-background-color: #fa5252;
    }
  }
  &-warning {
    .ag-cell {
      --ag-value-change-value-highlight-background-color: #fab005;
    }
  }
  &-info {
    .ag-cell {
      --ag-value-change-value-highlight-background-color: #fff3bf;
    }
  }
}

.ag-center-cols-clipper {
  // grid의 min height를 제거
  min-height: unset !important;
}

.cck-table-frame {
  font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 14px;
}

.cck-table-show-only-column {
  min-height: 40px;
}

.cck-table {
  color: styles.$gray-800;

  &-column-header-default {
    .ag-header-cell-label,
    .ag-header-cell-text {
      justify-content: left;
    }
  }

  &-column-header-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
    .ag-header-cell-label {
      justify-content: center;
    }
    .ag-header-select-all {
      margin-right: 0px !important;
    }
  }

  &-checkbox-select-all {
    .ag-cell-label-container {
      width: 0px !important;
    }
  }

  &-column-header-checkbox-default {
    .ag-header-cell-label,
    .ag-header-cell-text {
      justify-content: center;
    }
  }

  &-dropdown-icon {
    display: flex;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
  }

  &-label {
    display: flex;
    align-items: center;
    flex: 1 0 0;

    &-subhead2,
    &-body1 {
      @extend %typo_body_01;

      &-blue {
        color: styles.$blue-300;
      }
      &-gray {
        color: styles.$gray-300;
      }
      &-red {
        color: styles.$red-300;
      }
      &-amber {
        color: styles.$amber-300;
      }
      &-black {
        color: styles.$gray-800;
      }
      &-green {
        color: styles.$green-400;
      }
    }

    &-subhead2 {
      @extend %typo_subhead_02;
    }

    &-placeholder {
      color: styles.$gray-400;
    }

    &-align {
      &-center {
        justify-content: center;
      }
      &-right {
        justify-content: flex-end;
      }
    }
  }

  &-left-border-default::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 0;
    width: 1px;
    background-color: styles.$gray-200;
    height: 85%;
    transform: translateY(-50%);
  }

  &-cell-default {
    display: flex;
    align-items: center;
    line-height: 20px !important;
    flex-direction: column;
    height: 100%;
  }

  &-cell-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
    .ag-cell-wrapper {
      align-items: center;
      justify-content: center;

      .ag-selection-checkbox {
        margin-right: 0px !important;
      }
    }
  }

  &-cell-checkbox-default {
    display: flex;
    justify-content: center;
    align-items: center;
    .ag-react-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-cell-dropdown-default {
    padding-right: 0px !important;
  }

  &-dropdown-button-wrapper {
    display: flex;
    width: 100%;
    flex: 1 0 0;

    cursor: pointer;

    @extend %typo_body_01;
  }

  &-bottom-border-highlight {
    border-bottom: 1px solid styles.$blue-500 !important;
  }

  &-upload-column {
    display: flex !important;
    align-items: center;

    .ag-react-container {
      width: 100%;
    }
  }
}

.cck-table-buttons-frame {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  width: 100%;

  .cck-table-search-input {
    width: 360px;
    height: 40px;
    margin-right: auto;
  }
}

.cck-table-button-column {
  padding: 0 !important;
}

.cck-table-button {
  &-header-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .ag-header-cell-label,
    .ag-header-cell-text {
      justify-content: center;
    }
  }

  &-cell-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 4px 16px;
    gap: 20px;
  }

  // 불필요 style 삭제
  // &-cell-single {
  //   width: 72px;
  // }

  &-cell-double {
    width: fit-content;
  }
}

.cck-table-label-placeholder-none {
  .cck-table-label-placeholder {
    display: none;
  }
}

.cck-table-label-overflow-ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cck-table-click-disable {
  pointer-events: none;
}

.cck-table-upload-label {
  @extend %typo_body_01;

  color: styles.$gray-800;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cck-table-upload-header {
  .ag-header-cell-label {
    justify-content: center;
  }
}

.cck-table-custom-cell-frame {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  display: flex;
  align-items: center;
}

.cck-table-custom-header-frame {
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.table-content-empty-base {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 100%;
}

.table-content-empty {
  @extend .table-content-empty-base;

  .table-content-empty-image {
    height: 52px;
  }

  .table-content-empty-text {
    @extend %typo_body_02;

    color: styles.$gray-600;
  }
}

.table-content-empty-table {
  height: 40px;
  margin-bottom: -15px;
}

.table-content-empty-web {
  @extend .table-content-empty-base;
  background-color: styles.$gray-600;
  min-height: 250px;

  .table-content-empty-image {
    height: 54px;

    .cck-base-svg-icon-wrapper {
      .cck-base-icon {
        cursor: default !important;
      }
    }
  }

  .table-content-empty-text {
    @extend %typo_body_02;

    color: styles.$gray-300;
  }
}

.cck-table-checkbox-click-disable {
  pointer-events: none;
}

.cck-table-editor-text-area-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  // padding: 20px;
  gap: 20px;
}

.cck-table-editor-text-area-buttons {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.cck-table-editor-text-area {
  width: 400px;
  height: 500px;
  resize: none;
}

.cck-table-date-cell {
  display: flex !important;
  align-items: center;
  .ag-react-container {
    width: 100%;
  }
}

.cck-table-frame {
  .ag-row {
    > :first-child::before {
      background-color: transparent !important;
    }
  }
}

.cck-table-icon {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 40px;
}

// .cck-table-icon-column {
//   padding: 0 !important;
//   display: flex !important;
//   justify-content: center;
//   align-items: center;
// }

.cck-renderer-common {
  display: flex;
  align-items: center;
  min-height: 38px;
  padding: 10px 16px;
}

.cck-table-checkbox-selector {
  display: flex;
  justify-content: center;

  .ag-selection-checkbox {
    margin-right: 0px !important;
  }
}

.cck-table-checkbox-selector-header {
  display: flex;
  justify-content: center;

  .ag-header-select-all {
    margin-right: 0px !important;
  }

  .ag-cell-label-container {
    width: 0px !important;
  }
}

.cck-table-cell-label-scroll {
  display: flex;
  flex-direction: column;
  .ag-cell-wrapper {
    display: block;
    overflow-y: auto;
  }
  .ag-react-container {
    display: block;
    overflow-y: auto;
  }
}

.cck-full-size-input {
  width: 100% !important;
  height: 100% !important;
}

.ag-sort-order {
  display: none;
}

.cck-aggrid-cell-editor-input-textarea {
  padding-top: 6px;
  padding-bottom: 6px;
}

.cck-renderer-date-range {
  width: 100%;
  height: 100%;

  .ant-picker-input {
    font-family: Pretendard, "Apple SD Gothic Neo", sans-serif;
  }
}

.cck-table-label-group {
  padding: 0;
}

.ag-icon {
  padding-left: 8px;
}
