@use "../../styles";

$breadcrumb-frame-margin-top: 30px;
$breadcrumb-frame-content-height: 40px;

.breadcrumb-frame {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: $breadcrumb-frame-content-height;
  margin-top: $breadcrumb-frame-margin-top;

  .ant-breadcrumb-separator {
    @extend %typo_subhead_03;
    color: styles.$gray-500;
  }
}

.breadcrumb-prev-path {
  @extend %typo_subhead_03;

  color: styles.$gray-500;
  cursor: pointer;
  background-image: linear-gradient(styles.$gray-500 0 0);
}

.breadcrumb-current-path {
  @extend %typo_subhead_03;

  color: white;
  cursor: pointer;
  background-image: linear-gradient(white 0 0);
}

.breadcrumb-path {
  padding-bottom: 2px;
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition:
    background-size 0.3s,
    background-position 0s 0.3s; /*change after the size immediately*/
}

.breadcrumb-path:hover {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 2px;
}
