@use "../../styles";

$new-modal-bottom-padding: 30px;

.new-modal-root {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;

  .new-modal-dimm {
    position: absolute;
    width: inherit;
    height: inherit;
    background: #000000;
    opacity: 0.2;
  }

  .new-modal {
    display: flex;
    flex-direction: column;
    // align-items: center;
    position: absolute;
    top: 50%;
    margin: auto;
    background: white;
    box-shadow:
      0 4px 16px rgb(0 0 0 / 8%),
      0 24px 56px rgb(0 0 0 / 16%);
    border-radius: 5px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    max-width: 100vw;
    max-height: 100vh;
    overflow: auto;

    &-header-frame {
      width: 100%;
      padding: 26px 30px 20px 30px;
    }

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 143px;
      width: 100%;
    }
  }

  .new-modal-bottom-padding {
    padding: 0 0 $new-modal-bottom-padding 0;
  }

  .modal-header-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin: 20px;
  }

  .modal-header-main-title {
    @extend %typo_headline;

    color: styles.$gray-800;
  }

  .modal-header-sub-title {
    @extend %typo_body_02;

    color: styles.$gray-600;
    margin-left: 10px;
  }
}

.modal-body-text {
  @extend %typo_body_02;
  height: 144px;
  width: 403px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .modal-body-inner-text {
    color: styles.$gray-900;
  }

  .modal-body-inner-only-text {
    color: styles.$gray-800;
  }

  .modal-body-inner-sub-text {
    @extend %typo_body_01;

    padding: 8px 14px 0 14px;
    color: styles.$gray-600;
    text-align: center;
  }
}

.modal-footer-frame {
  width: 100%;
  padding: 0 20px;
}

.modal-body-log-list-frame {
  width: 676px;
  height: 229px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  padding-top: 20px;
  padding-left: 20px;

  color: white;
  background-color: styles.$gray-750;

  .modal-body-log-list-title {
    margin-bottom: 14px;
    @extend %typo_subhead_02;
  }

  .modal-body-log-list-wrapper {
    display: flex;
    flex-direction: column;
    height: 170px;
    margin-right: 12px;
    gap: 5px;

    overflow: auto;

    @extend %typo_body_02;
  }

  .modal-body-log-list-wrapper::-webkit-scrollbar {
    width: 4px;
  }
  .modal-body-log-list-wrapper::-webkit-scrollbar-thumb {
    background: styles.$gray-200;
    border-radius: 10px;
    flex-shrink: 0;
  }

  .modal-body-log-list-wrapper::-webkit-scrollbar-track {
    background: styles.$gray-750;
  }

  .modal-body-log-list-content-wrapper {
    width: 630px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 5px;
    background-color: styles.$gray-700;

    padding: 6px 0px 7px 8px;

    .span-highlight-blue {
      color: styles.$blue-200;
    }
  }
}

.modal-table-body-frame {
  .modal-table-body-content {
    height: 530px;
    width: 400px;
    margin-top: 25px;
    margin-bottom: 35px;
  }
}

.new-modal-draggable {
  cursor: move;
}