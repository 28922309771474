$app-root-max-width: 1920px;
$container-width: 1500px;
$container-padding: 0 30px;

$text-red-color: rgb(250, 112, 112);
$text-gray-color: rgb(171, 169, 171);
$text-blue-color: rgb(17, 136, 245);
$text-indigo-color: #364FC7;
$border-color: rgba(171, 169, 171, 0.3);
$border: 1px solid $border-color;
$border-grey-color: #DEE2E6;
$background-grey-color: #F1F3F5;

$selected-item-background-blue-color: rgba(52, 149, 235, .6);