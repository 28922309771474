@use "@cck/common/dist/styles"

.icon-button-wrapper
  display: flex
  justify-content: center
  align-items: center
  width: 30px
  height: 30px

.base-full-size-style
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%

.chat-main-view-frame
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center

.chat-main-view-header
  @extend %typo_body_01
  text-align: center
  color: #fff
  height: 50px
  background-color: white
  border-bottom: 1px solid styles.$gray-300
  color: black
.chat-main-view-header-frame
  height: 100%
  width: 100%
  display: flex
  align-items: center
  gap: 16px
  .chat-main-view-header-frame-left
    display: flex
    align-items: center
    gap: 8px
    .chat-main-view-header-bookmark
        fill: styles.$amber-300
        path
          stroke: styles.$amber-300
    .chat-main-view-tag
      @extend %typo_caption
      color: styles.$marine-500
      padding: 1px 5px
      border-radius: 5px
      border: 1px solid styles.$marine-500
  .chat-main-view-header-frame-right
    @extend %typo_body_01
    color: styles.$gray-600

.chat-main-view-content
  position: relative
  min-height: 120px
  color: #fff
  display: flex
  flex-direction: column
  align-items: center
  padding: 0 20px
  
.chat-main-view-content-scroll-frame
  width: 100%
  height: 100%
  padding: 20px 0px 20px 0px
  max-width: 1200px

.chat-main-view-layout
  width: 100%
  height: 100%

.chat-main-view-content-empty-header
  @extend %typo_body_02
  font-size: 18px
  .chat-main-view-content-empty-header-1
    color: styles.$marine-600
    font-weight: 700
  .chat-main-view-content-empty-header-2
    color: styles.$marine-300
  .chat-main-view-content-empty-header-3
    color: styles.$gray-300
  .chat-main-view-content-empty-header-4
    color: styles.$gray-700
    
.chat-input-wrapper
  width: 100%
  max-width: 800px
  display: flex
  flex-direction: column
  padding: 0 20px
  .chat-input-editor
    display: flex
    flex-direction: column
    .chat-input-left-icon
      position: relative
      left: 20px
      bottom: 42px
      height: 0
      width: 22px
    .chat-input-right-icon
      position: relative
      left: calc( 100% - 44px )
      bottom: 42px
      height: 0
      width: 22px
    .chat-input
      @extend %typo_body_01
      width: 100%
      color: black
      padding: 20px 60px
    .chat-input:focus
      border-color: styles.$marine-500 !important
    .chat-input:hover
      border-color: styles.$marine-500 !important
.chat-input-question-example-header
  @extend %typo_subhead_02
  color: black
  padding-top: 70px
  padding-bottom: 20px
  width: 100%
  display: flex
  gap: 7px
  align-items: center
  justify-content: center

.chat-input-question-example-content
  width: 100%
  display: flex
  flex-wrap: wrap
  gap: 20px 14px
  .chat-input-example
    @extend %typo_body_02
    color: styles.$gray-900
    width: calc( 50% - 7px )
    display: flex
    gap: 12px
    align-items: center
    justify-content: center
    background-color: styles.$marine-100
    border-radius: 5px
    padding: 12px 10px
    cursor: pointer
.chat-main-view-content-empty
  .chat-main-view-content-empty-header
    position: absolute
    top: 30%
  .chat-input-wrapper
    position: absolute
    top: calc( 30% + 67px )

.chat-main-view-content-notEmpty
  .chat-input-wrapper
    padding-bottom: 50px