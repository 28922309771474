@use "@cck/common/dist/styles"

.chat-message-wrapper
  width: 100%
  flex-direction: column
  display: flex
  gap: 10px
  padding: 0px 30px 0px 30px

  .chat-message-base
    display: flex
    width: 100%
    padding: 10px 0px 10px 0px

  .chat-message-question-wrapper
    @extend .chat-message-base
    @extend %typo_display_01
    justify-content: space-between
    flex-direction: row
    align-items: center
    color: #000

  .chat-message-reference-wrapper
    @extend .chat-message-base
    flex-direction: column
    color: #000
    gap: 10px

    .chat-message-reference-title-wrapper
      @extend %typo_subhead_04
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between

      .chat-message-reference-title-left-wrapper
        display: flex
        flex-direction: row
        gap: 10px
        align-items: center

      .chat-message-reference-title-right-wrapper
        display: flex
        flex-direction: row
        gap: 10px
        align-items: center

    .chat-message-reference-content-wrapper
      display: flex
      align-items: center
      flex-direction: row
      justify-content: space-between
      width: 100%
      gap: 10px

      .chat-message-reference-content-title-wrapper
        display: flex
        flex-direction: row
        align-items: center
        gap: 10px

        .chat-message-reference-content-index-wrapper
          @extend %typo_caption
          width: 18px
          height: 18px
          display: flex
          align-items: center
          justify-content: center
          border: 1px solid styles.$gray-600
          border-radius: 3px
          padding: 4px

        .chat-message-reference-content-description-wrapper
          @extend %typo_body_02
          overflow: hidden
          text-overflow: ellipsis
          display: -webkit-box
          -webkit-line-clamp: 1
          -webkit-box-orient: vertical

      .chat-message-reference-content-option-wrapper
        display: flex
        flex-direction: row
        gap: 10px
        align-items: center

        .chat-message-reference-content-option-base
          display: flex
          justify-content: center
          align-items: center
          border-radius: 3px
          padding: 4px

        .chat-message-reference-content-option-arrow
          @extend .chat-message-reference-content-option-base
          background: styles.$marine-100

          &:hover
            background: styles.$marine-200
            // box-shadow: 0px 0px 5px 0px styles.$marine-300
            scale: 1.1

        .chat-message-reference-content-option-eye
          @extend .chat-message-reference-content-option-base
          background: styles.$gray-200

          &:hover
            background: styles.$gray-300
            // box-shadow: 0px 0px 5px 0px styles.$gray-400
            scale: 1.1


  .chat-message-answer-wrapper
    @extend .chat-message-base
    flex-direction: column
    color: #000

    .chat-message-answer-title-wrapper
      @extend %typo_subhead_04
      display: flex
      flex-direction: row
      gap: 10px
      align-items: center

    .chat-message-answer-content
      @extend %typo_body_long_02
      width: 100%
      padding: 20px 10px 20px 10px
      display: flex
      flex-direction: column
      gap: 5px

      p
        margin-bottom: 4px

      span
        @extend %typo_caption
        position: relative
        border: 1px solid styles.$gray-600
        border-radius: 3px
        padding: 3px 5px
        top: -2px
        cursor: pointer
        margin-left: 5px

        &:hover
          background-color: styles.$gray-200
          scale: 1.1


    .chat-message-answer-option-wrapper
      display: flex
      flex-direction: row
      gap: 10px
      align-items: center
      justify-content: flex-start

      .chat-message-answer-option


  .chat-message-request-question-wrapper
    display: flex
    flex-direction: column
    color: #000
    gap: 20px

    .chat-message-request-question-title-wrapper
      @extend %typo_subhead_03
      display: flex
      flex-direction: row
      gap: 10px
      align-items: center

    .chat-message-request-question-content-wrapper
      display: flex
      flex-direction: column
      gap: 10px

      .chat-message-request-question-content-item
        @extend %typo_body_01
        display: flex
        background-color: styles.$gray-100
        padding: 5px 10px
        border-radius: 5px
        width: fit-content

        &:hover
          background-color: styles.$gray-200
          scale: 1.05