@use "../../styles";

.auto-input-text-frame {
  @extend %typo_body_01;

  /* width: calc(
    100% - $search-input-control-padding-left - $search-input-control-padding-right
    - $search-input-control-child-gap - $search-input-control-icon-size
  ); */
  width: 100%;
  height: 100%;
  border: none;

  .ant-select-selector {
    width: 100%;
    height: 100%;

    .ant-select-selection-search {
      width: 100%;
      height: 100%;

      .ant-input-affix-wrapper {
        border: 0 !important;
        background: none !important;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .ant-input-affix-wrapper-focused {
        border: 0 !important;
      }

      .ant-input {
        border: 0 !important;
        background: none !important;

        @extend %typo_body_01;

        width: 100%;
        height: 100%;
      }
    }
  }
}

.auto-input-text-frame:focus {
  outline: none;
}

.auto-dropdown-item {
  @extend %typo_body_01;

  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 36px;
}
