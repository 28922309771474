@use '@cck/common/dist/styles';
@use 'old_styles';

.new-standard-menu-view-frame {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.new-standard-menu-view-header-frame {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  .new-standard-menu-view-header-title {
    @extend %typo_subhead_04;
    color: black;
  }

  .new-standard-menu-view-header-guide-text {
    @extend %typo_caption;
    color: black;
  }
}

.new-standard-menu-view-body-frame {
  display: flex;
  gap: 10px;
  width: 100%;
}

.new-standard-menu-item {
  @extend %typo_body_01;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px 16px;
  border: 1px solid styles.$gray-400;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: white;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-color: styles.$gray-200;
  }

  .new-standard-menu-item-search-count-frame {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}

.new-standard-menu-item-main-view {
  @extend %typo_body_01;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border: 1px solid styles.$marine-200;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: white;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: styles.$marine-200;
  }
}

.new-standard-submenu-item {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 260px;
  min-height: 28px;
  padding: 10px 16px;
  border-radius: 5px;
  // border: 1px solid transparent;
  border: 1px solid styles.$marine-200;

  &:hover {
    background-color: styles.$marine-200;
  }
  .new-standard-submenu-item-id {
    color: styles.$gray-500;
  }

  .new-standard-submenu-item-name {
    color: black;
  }

  .new-standard-submenu-item-search-count-frame {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid styles.$gray-400;
  }
}