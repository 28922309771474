@use "../../styles";

@keyframes button-click-animation {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

// shadow
.button-shadow-frame {
  box-shadow:
    0px 3px 8px 0px rgba(0, 0, 0, 0.12),
    0px 1px 2px 0px rgba(0, 0, 0, 0.08);
}

// size
@mixin button_size_large {
  @extend %typo_subhead_03;
  height: 48px;
}
@mixin button_size_medium {
  @extend %typo_body_01;
  height: 35px;
}
@mixin button_size_small {
  @extend %typo_body_long_01;
  height: 28px;
}
@mixin button_size_xsmall {
  @extend %typo_caption;
  height: 24px;
}

// frame
@mixin button_common_frame {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 2px;
  cursor: pointer;
}

.button-frame-large {
  @include button_common_frame;
  @include button_size_large;
  padding: 10px 16px;
  gap: 8px;
}
.button-frame-medium {
  @include button_common_frame;
  @include button_size_medium;
  padding: 10px;
  gap: 8px;
}
.button-frame-small {
  @include button_common_frame;
  @include button_size_small;
  padding: 0px 10px;
  gap: 4px;
}
.button-frame-xsmall {
  @include button_common_frame;
  @include button_size_xsmall;
  padding: 0px 8px;
  gap: 4px;
}

$button-disabled-opacity: 0.4;

// color
// primary
.button-color {
  &-primary {
    &-normal {
      background-color: styles.$blue-500;
      color: white;
      &:hover {
        background-color: styles.$blue-700;
      }
    }

    &-disabled {
      background-color: styles.$blue-700;
      color: white;
      opacity: $button-disabled-opacity;
    }
  }

  &-secondary {
    &-normal {
      background-color: styles.$gray-100;
      color: styles.$gray-600;
      &:hover {
        background-color: styles.$gray-200;
      }
    }
    &-disabled {
      background-color: styles.$gray-50;
      color: styles.$gray-600;
      opacity: $button-disabled-opacity;
    }
  }

  &-outline {
    &-normal {
      background-color: white;
      color: styles.$gray-800;
      border: 1px solid styles.$gray-500;
      &:hover {
        background-color: styles.$gray-200;
      }
    }
    &-disabled {
      background-color: white;
      color: styles.$gray-600;
      border: 1px solid styles.$gray-200;
      opacity: $button-disabled-opacity;
    }
  }

  &-warning {
    &-normal {
      background-color: styles.$red-400;
      color: white;
      &:hover {
        background-color: styles.$red-500;
      }
    }
    &-disabled {
      background-color: styles.$red-400;
      opacity: $button-disabled-opacity;
    }
  }

  &-gray {
    &-normal {
      background-color: styles.$gray-600;
      color: white;
      &:hover {
        background-color: styles.$gray-700;
      }
    }
    &-disabled {
      background-color: styles.$gray-600;
      color: white;
      opacity: $button-disabled-opacity;
    }
  }

  &-black {
    &-normal {
      background-color: styles.$gray-800;
      color: white;
      &:hover {
        background-color: styles.$gray-900;
      }
    }
    &-disabled {
      background-color: styles.$gray-800;
      color: white;
      opacity: $button-disabled-opacity;
    }
  }

  &-green {
    &-normal {
      background-color: styles.$green-600;
      color: white;
      &:hover {
        background-color: styles.$green-700;
      }
    }
    &-disabled {
      background-color: styles.$green-600;
      color: white;
      opacity: $button-disabled-opacity;
    }
  }

  &-skyblue {
    &-normal {
      background-color: styles.$blue-100;
      color: styles.$blue-500;
      &:hover {
        background-color: styles.$blue-200;
      }
    }
    &-disabled {
      background-color: styles.$blue-100;
      color: styles.$blue-500;
      opacity: $button-disabled-opacity;
    }
  }

  &-marine {
    &-normal {
      background-color: styles.$marine-100;
      color: styles.$marine-500;
      &:hover {
        background-color: styles.$marine-200;
      }
    }
    &-disabled {
      background-color: styles.$marine-100;
      color: styles.$marine-500;
      opacity: $button-disabled-opacity;
    }
  }

  &-no-border {
    &-normal {
      color: styles.$gray-800;
      &:hover {
        background-color: styles.$gray-100;
        color: styles.$gray-600;
      }
    }
    &-disabled {
      color: styles.$gray-600;
      opacity: $button-disabled-opacity;
    }
  }
}

// additional selector
.button-list-frame {
  display: flex;
  gap: 15px;
}

.button-list-frame-align-center {
  justify-content: center;
}

.button-list-frame-align-right {
  justify-content: right;
}

.button-list-frame-align-between {
  justify-content: space-between;
}

.button-wrapper-small {
  width: 102px;
}

.button-wrapper-middle {
  width: 168px;
}

.button-wrapper-large {
  width: 204px;
}

.button-click-animation {
  animation: button-click-animation 0.5s ease-in-out;
}
