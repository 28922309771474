@use '@cck/common/dist/styles';
@use 'old_styles';

$new-lnb-view-frame-child-gap: 10px;

$new-lnb-view-one-depth-dropdown-height: 40px;


.new-lnb-view-frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  gap: $new-lnb-view-frame-child-gap;

  .new-lnb-view-one-depth-dropdown-frame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $new-lnb-view-one-depth-dropdown-height;
    padding: 10px;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 5px;
  }

  .new-lnb-view-one-depth-dropdown-frame-disabled {
    background-color: rgba(0, 0, 0, 0.04);
    color: styles.$gray-400;
    border: 1px solid styles.$gray-400;
  }

  .new-lnb-view-one-depth-dropdown-frame-has-item {
    background-color: white;
    color: black;
  }

  .new-lnb-view-one-depth-dropdown-text-frame {
    display: flex;
    gap: 5px;
    align-items: center;

    .new-lnb-view-one-depth-dropdown-text-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    }

    .new-lnb-view-one-depth-dropdown-text {
      @extend %typo_body_01;
      width: 170px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.new-lnb-two-depth-list-frame {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - $new-lnb-view-frame-child-gap * 2 - $new-lnb-view-one-depth-dropdown-height - 35px);
  display: flex;
  gap: 20px;

  .new-lnb-two-depth-list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 16px 16px 16px;
    width: 100%;
    height: 100%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    overflow-y: auto;
  }

  .new-lnb-two-depth-list::-webkit-scrollbar {
    display: none;
  }
}

.new-lnb-one-depth-dropdown-overlay-frame {
  max-height: 600px;
  overflow: scroll;
}

.new-lnb-one-depth-dropdown-overlay-frame::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.new-lnb-one-depth-dropdown-overlay-frame::-webkit-scrollbar-corner {
  background-color: transparent;
}

.new-lnb-one-depth-dropdown-overlay-frame::-webkit-scrollbar-thumb {
  background-color: styles.$gray-200;
  border-radius: 8px;
  cursor: pointer;
}

.new-lnb-one-depth-dropdown-overlay-frame::-webkit-scrollbar-thumb:hover {
  background-color: styles.$marine-200;
  border-radius: 8px;
  cursor: pointer;
}

.new-lnb-view-search-frame {
  display: flex;
  align-items: center;
  position: relative;
  gap: 4px;
  width: 100%;
  height: 35px;
}

.new-lnb-search-history-popover-frame {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100%;
  min-height: 100px;
  z-index: 10;
  background-color: white;
  border-radius: 8px;
  border: 1px solid styles.$gray-200;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  padding: 15px;

  .new-lnb-search-history-popover-title-frame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .new-lnb-search-history-popover-title-text {
      @extend %typo_subhead_01;
      color: black;
    }

    .new-lnb-search-history-popover-title-delete {
      @extend %typo_caption;
      color: styles.$red-500;
      cursor: pointer;
    }
  }
}