@use "../../styles";

.cck-base-checkbox {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 2px;
}

.checkbox-state-enable {
    background-color: white;
    border: 1px solid styles.$gray-500;
    border-radius: 2px;
}

.checkbox-state-check {
    background-color: styles.$blue-700;
}

.checkbox-state-disable {
    background-color: styles.$gray-100;
    color: styles.$gray-500;
    border: 1px solid styles.$gray-500;
}
