@use "../../styles";

.backdrop-content-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.backdrop-guide-text {
  @extend %typo_display_02;

  color: white;
}

.ant-backdrop-content-frame {
  width: 100%;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 255;

  .ant-backdrop-content {
    @extend %typo_headline;
    position: relative;
    top: 50px;
    color: white;
  }
  .ant-spin-container::after {
    background-color: transparent;
  }
  .ant-spin-blur {
    opacity: 1;
  }
  .ant-backdrop-content-dimm {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.2;
    background-color: black;
  }
}
